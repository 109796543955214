body{
width: 100%;
height: 100%;
}

#myCanvas {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: auto;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
